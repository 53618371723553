
  import UploadButton from './UploadButton';
  import UploadFilesList from './UploadFilesList';
  import ProductDesign from '../configureproduct/ProductDesign';
  import DesignModal from '../configureproduct/DesignModal';
  import UploadArtworksList from './UploadArtworksList';
  import Signin from './../cartitemsadded/SignInForm';
  import auth from '@/plugins/authMixins';
  import { mediaHasClassTypeName } from '@/utils/media';

  import { mapGetters } from 'vuex';

  export default {
    name: 'UploadModalButton',
    mixins: [auth],
    components: {
      UploadButton,
      UploadFilesList,
      UploadArtworksList,
      Signin,
      DesignModal,
      ProductDesign,
    },
    props: {
      value: {
        type: Array,
        required: true,
      },
      progressTitle: {
        type: String,
      },
      folder: {
        type: String,
      },
      onFilesChange: {
        type: Function,
      },
      loadingData: {
        type: Boolean,
        default: () => false,
      },
      multiple: {
        type: Boolean,
        default: () => false,
      },
      configureProduct: {
        type: Object,
        default: () => false,
      },
      hasSignin: {
        type: Boolean,
        default: () => false,
      },
      isDesignArtworkExist: {
        type: Boolean,
      },
      selectedPart: {
        type: Object,
      },
    },
    data() {
      return {
        updated: 1,
        storeArtworks: { data: [], count: 0, page: 1 },
        customerArtworks: [],
        companyLogos: [],
        uploadedArtworks: [],
        customer: {},
        error: '',
      };
    },
    watch: {
      uploadedArtworks() {
        if (this.uploadedArtworks.length > 0) {
          this.value.push(...this.uploadedArtworks.filter((i) => i));
          this.uploadedArtworks = [];
          this.$refs.artworks.hide();
        }
      },
    },
    computed: {
      ...mapGetters({
        storeInformation: 'storeInformation',
        loggedInCustomer: 'auth/loggedInUser',
      }),
      limitCountOfArtworkToUploadPerOrder() {
        return typeof this.storeInformation.limitCountOfArtworkToUploadPerOrder === 'number'
          ? this.storeInformation.limitCountOfArtworkToUploadPerOrder
          : Infinity;
      },
      productLogos() {
        return (this.selectedPart?.mediaList || []).filter((i) => mediaHasClassTypeName(i, 'Logo')) || [];
      },
      defaultArtworks() {
        const list =
          this.productLogos.length > 0
            ? this.productLogos
            : this.storeArtworks.data.map((e) => ({ ...e, parentCollection: 'storeartworks' }));

        const d = [
          ...list
            .reduce((images, image) => {
              images.set(image.url || image.location, image);
              return images;
            }, new Map())
            .values(),
        ];
        if (this.configureProduct.allowCompanyLogos && this.companyLogos.length) d.unshift(...this.companyLogos);
        return d.map((e) => ({ ...e, parent: e._id, parentCollection: e.parentCollection || 'productMedia' }));
      },
    },
    methods: {
      previewImage(file) {
        const media = (this.value || []).filter((file) => !!file.fileThumbLocation);
        const index = media.findIndex((m) => m.fileLocation === file.fileLocation);
        this.$previewFiles(media, index);
      },
      selectArtwork(artwork) {
        const fileExt =
          artwork.fileExt || (artwork.location || artwork.url || artwork.fileLocation || '').split('.')[1];
        const fileLocation = artwork.location || artwork.url || artwork.fileLocation || '';
        const newArtwork = {
          ...artwork,
          fileThumbLocation: artwork.locationResized || artwork.urlSmall || artwork.url,
          fileLocation,
          fileName: fileLocation.split('/')[fileLocation.split('/').length - 1],
          fileExt,
        };

        this.uploadedArtworks = [...this.uploadedArtworks, newArtwork];

        if (this.configureProduct?.decorationArtwork?.artworkCategoryModal && !this.productLogos.length) {
          this.$refs.designModal.$refs.designs.hide();
        }
      },
      async signIn() {
        await this.$refs.signIn.$validator.validateAll();
        if (this.$refs.signIn.formIsInvalidMessage) {
          this.error = this.$refs.signIn.formIsInvalidMessage;
          return;
        }
        await this.logIn({ ...this.customer, storeUrl: this.storeInformation.storeUrl }, true);
        await this.loadCustomerArtworks();
        await this.loadCompanyLogos();
      },
      async addArtworks() {
        if (this.configureProduct?.decorationArtwork?.artworkCategoryModal && !this.productLogos.length) {
          this.$refs.designModal.$refs.designs.show();
        } else {
          this.$refs.artworks.show();
          const params = this.loggedInCustomer?.roles?.length
            ? { roles: this.loggedInCustomer.roles.map((e) => e._id || e) }
            : {};

          if (!this.storeArtworks.count || this.storeArtworks.data.length < this.storeArtworks.count) {
            try {
              this.loading = true;
              if (this.storeArtworks.data.length) params.page = ++this.storeArtworks.page;
              const artworks = await this.$api.stores.getStoreArtworks(this.storeInformation._id, { params });
              this.storeArtworks.count = artworks.count;
              this.storeArtworks.data.push(...artworks.data);
            } catch (e) {
            } finally {
              this.loading = false;
            }
          }
          this.updated += 1;
          this.loadCustomerArtworks();
          this.loadCompanyLogos();
        }
      },
      async loadCustomerArtworks() {
        if (this.loggedInCustomer) {
          this.customerArtworks = await this.$api.customers.getCustomerArtworks(this.loggedInCustomer._id);
        }
      },
      async loadCompanyLogos() {
        const companyId = this.loggedInCustomer?.companyId?._id || this.loggedInCustomer?.companyId;
        if (companyId) {
          this.companyLogos = (await this.$api.uploads.getUploads(companyId)).data;
        }
      },
    },
  };
